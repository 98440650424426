import React from "react";

function SyntaxExplain() {
  // use https://dillinger.io/
  return (
    <ul class="modal-content">
      <li class="has-line-data" data-line-start="0" data-line-end="1">
        By default all query words are <strong>OR</strong> relationship
      </li>
      <li class="has-line-data" data-line-start="1" data-line-end="3">
        Use quotes to search for a specific multi-word <strong>phrase</strong>.
        <br />
        e.g. <code>&quot;gold nanoparticles&quot;</code>
      </li>
      <li class="has-line-data" data-line-start="3" data-line-end="5">
        Use -query_term to specify that the result <strong>must not</strong>{" "}
        include the term.
        <br />
        e.g. <code>nanoparticle -nanorod</code> 
      </li>
      <li class="has-line-data" data-line-start="7" data-line-end="9">
        To search specific <strong>fields</strong> use fieldname:query_term.
        <br />
        e.g. <code>title:Computational</code>
      </li>
    </ul>
  );
}

export default SyntaxExplain;
