import React, { useEffect, useReducer } from "react";
import { Router } from "@reach/router";
import { Flex, Box } from "rebass";
import ErrorPage from "./shared/components/Error";
import NavMenu from "./shared/components/NavMenu";
import Search from "./Search/index"
import Article from "./Article/index"
import Footer from './shared/components/Footer';

function App() {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Box width={1}>
        <NavMenu />
      </Box>
      <Router primary={false} component={React.Fragment}>
        <Search path="/" />
        <Article path="/article/:id" />
        <ErrorPage default />
      </Router>
    </Flex>
  );
}

export default App;
