function Get(url, params) {
  return Fetch("GET", url, params);
}

function Post(url, params) {
  return Fetch("POST", url, params);
}

function Put(url, params) {
  return Fetch("PUT", url, params);
}

function Patch(url, params) {
  return Fetch("PATCH", url, params);
}

function Delete(url, params) {
  return Fetch("DELETE", url, params);
}

function Fetch(method, url, params) {
  params = params || {};
  params.method = method;
  params.credentials = "same-origin"; //Not default for all browsers yet

  const fetcher = () =>
    fetch(url, params)
      // Reject promise if response is not OK
      .then((response) => {
        if (response.ok) return response;

        return response.text().then((text) => {
          let message = text;
          try {
            const json = JSON.parse(text);
            if ("message" in json) message = json.message;
          } catch (e) {
            // not JSON
          }
          return Promise.reject({ message, code: response.status });
        });
      })
      // automatically return the data if it's a known content type
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!contentType || params.returnRaw) return response;
        if (contentType.includes("application/json")) {
          return response.json();
        } else if (contentType.includes("text/plain")) {
          return response.text();
        }
        return response;
      });

  return fetcher;
}

export { Get, Post, Put, Patch, Delete };
