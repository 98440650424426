import React, { useReducer, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Get } from "../shared/Fetcher";
import styled from "styled-components";
import { saveAs } from "file-saver";

const StyledButton = styled(Button)`
  &&& {
    background: #ffffff00;
  }
`;

function DownloadButton({ doi, numParagraph }) {
  //   const puredoi = doi.replace("https://doi.org/", "");
  const url = `/document/v1/article/article/docid/${doi}/${numParagraph}`;
  //   console.log(url);
  function reducer(state, action) {
    const { response, error, url } = state;
    switch (action.type) {
      case "loading":
        return {
          loading: true,
          url: action.url,
          response: null,
          error: null,
        };
      case "success":
        return { loading: false, response: action.response, error, url };
      case "error":
        return { loading: false, error: action.error, response, url };
      default:
        throw new Error();
    }
  }

  const initialState = {
    loading: null,
    response: null,
    error: null,
    url: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  //   console.log(state);

  useEffect(() => {
    if (state.loading) {
      //   console.log("dispatch loading", state, url);
      let isCanceled = false;
      // new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(Get(url)());
      //   }, 5000);
      // })
      Get(url)()
        .then((response) => {
          if (!isCanceled) {
            // console.log("dispatch success");
            // console.log(response);
            dispatch({
              type: "success",
              response: response,
            });
          } else {
            console.log("response cancled");
          }
        })
        .catch((error) => {
          if (!isCanceled) {
            // console.log("dispatch error");
            dispatch({ type: "error", error: error });
          } else {
            // console.log("error cancled");
          }
        });
      return () => {
        // console.log("cancel", state, url);
        isCanceled = true;
      };
    } else if (state.response) {
      const downloadJson = JSON.stringify({
        id: `${state.response.fields.doi}/${state.response.fields.numParagraph}`,
        text: state.response.fields.content,
        label: [],
      });
      // console.log(downloadJson)
      var blob = new Blob([downloadJson], { type: "application/json" });
      saveAs(
        blob,
        `${state.response.fields.doi}/${state.response.fields.numParagraph}.json`
      );
    } else {
      //   console.log("same body useEffect", state, url);
    }
  }, [state, url]);

  return (
    <StyledButton
      icon="download"
      onClick={() => {
        console.log("click");
        dispatch({ type: "loading", url: url });
      }}
      size="mini"
    ></StyledButton>
  );
}

export default DownloadButton;
