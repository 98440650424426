import React, { useState } from "react";
import { Card, Container, Label, Popup } from "semantic-ui-react";
import Moment from "react-moment";
import styled from "styled-components";
import Link from "../shared/components/Link";
import DownloadButton from "../Article/download";

const StyledCard = styled(Card)`
  && {
    box-shadow: none;
    margin-bottom: 2em;
    a {
      color: #005a8e;
    }
    a:hover {
      color: #1a7db6;
    }
  }
  &.card {
    width: 100%;
  }
  .header {
    font-weight: bold;
    padding: 5px;
  }
  &.card .meta {
    font-size: 0.9em;
    padding: 0 0.5em;
    color: rgba(48, 48, 48, 0.5);
    a.doi {
      float: right;
    }
  }
  .meta:after {
    content: "";
    display: table;
    clear: both;
  }
  && .content {
    padding: 0.3em 0.5em;
    border: 0;
    text-align: justify;
  }
`;

const highlightRegex = /<hi>(.*?)<\/hi>/g;
const formatText = (text) => {
  if (!text) return null;
  return (
    text
      .replace(/<sep \/>/g, "…")
      .split(highlightRegex)
      // The highlighted sections will be every other element starting at 1, wrap these in bold while keep others as strings
      .map((r, i) => (i % 2 === 0 ? r : <b key={i}>{r}</b>))
  );
};

// Link which has a dummy target which is ignored, only triggers the onClick callback
const FunctionLink = ({ onClick, ...props }) => (
  <a
    href="#root"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {props.children}
  </a>
);

function JournalAndDate({ journal, timestamp }) {
  const format = journal ? " (YYYY-MM-DD)" : "YYYY-MM-DD";
  return (
    <>
      {journal && (
        <>
          <b>Journal:</b> {journal}
        </>
      )}
      {((timestamp > 0) && (timestamp != 4102462800000))?( 
        <Moment format={format} unix utc>
          {timestamp}
        </Moment>
      ) : null}
    </>
  );
}

function DoiLink({ doi }) {
  if (!doi) return null;
  return (
    <Link className="ui doi" to={doi}>
      {doi.replace("https://doi.org/", "doi:")}
    </Link>
  );
}

function SourceAndCitations({ source, title, citations_count_total }) {
  const showCitations = citations_count_total > 0;
  if (!source) return null;
  return (
    <div>
      {source && (
        <>
          <b>Source: </b>
          {source}
        </>
      )}
      {source && showCitations && <>, </>}
      {showCitations && (
        <>
          <b>Citations: </b>
          {citations_count_total}
        </>
      )}
    </div>
  );
}

function SectionTitle({ title }) {
  if (!title) return null;
  return (
    <div>
      {title && (
        <>
          <b>Section Title: </b>
          {title}
        </>
      )}
    </div>
  );
}

function AuthorsList({ authors }) {
  const [showAll, setShowAll] = useState(false);

  if (!authors) return null;
  const limit = showAll || authors.length < 6 ? authors.length : 6;

  if (limit < authors.length) {
    return (
      <div>
        <b>Authors: </b>
        {authors.slice(0, 3).join(",  ") + " . . . "}
        {authors.slice(-3).join(",  ") + " "}
        <FunctionLink onClick={() => setShowAll(true)}>
          and {authors.length - limit} more
        </FunctionLink>
      </div>
    );
  } else {
    return (
      <div>
        <b>Authors: </b>
        {authors.join(",  ")}
      </div>
    );
  }
}

function ResultCard({ fields }) {
  // console.log(fields)
  const {
    doi,
    // publisher,
    journal,
    titleSection,
    numParagraph,
    citation,
    timestamp,
    authors,
  } = fields;
  const linkdoi = doi.startsWith("https") ? doi : "https://doi.org/" + doi;
  const content = formatText(fields["content"]);
  const plainTitle = fields["title"].replace(highlightRegex, "$1");
  const publisher = null;
  const articleURL = `${doi}/${numParagraph}`.replaceAll(/\//g, "%2F");
  return (
    <StyledCard>
      <Card.Header>
        <Link to={`/article/${articleURL}`}>{plainTitle}</Link>
      </Card.Header>
      <Card.Meta>
        {/* Spread syntax allows array elements to be passed in as individual arguments */}
        {/* takes in an iterable (e.g an array) and expands it into individual elements */}
        <SectionTitle title={titleSection} />
        <JournalAndDate journal={journal} timestamp={timestamp} />
        <DoiLink doi={linkdoi} />
        <SourceAndCitations
          source={publisher}
          citations_count_total={citation}
        />
        <AuthorsList authors={authors} />
      </Card.Meta>
      {content && (
        <Card.Content>
          <Popup
            position="top center"
            content="Artcle title and its abstract / Sentences from article full text"
            trigger={<Label horizontal>Content</Label>}
            component={"span"}
          />
          {content}
        </Card.Content>
      )}
    </StyledCard>
  );
}

export default ResultCard;
