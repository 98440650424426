import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import Moment from "react-moment";
import { navigate } from "@reach/router";
import { uniq } from "lodash";
import { Container, Header, Tab, List } from "semantic-ui-react";
import { Error, Loading } from "../shared/components/Message";
import { Get } from "../shared/Fetcher";
import ResultCard from "../Search/ResultCard";
import Link from "../shared/components/Link";
import { nameFormatter } from "../shared/utils/formatter";
import Pagination from "../shared/components/Pagination";
import Footer from "../shared/components/Footer";

const ContainerContent = styled(Container)`
  &&& {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

function Authors({ authors }) {
  if (!authors) return null;
  return (
    <Header.Subheader>{authors.map(nameFormatter).join(", ")}</Header.Subheader>
  );
}

function Meta({ journal, timestamp, source, license, doi }) {
  const linkdoi = doi.startsWith("https") ? doi : "https://doi.org/" + doi;
  const format = journal ? " (YYYY-MM-DD)" : "YYYY-MM-DD";
  const date = timestamp ? (
    <Moment format={format} unix utc>
      {timestamp * 1000}
    </Moment>
  ) : null;

  return (
    <List>
      {doi && (
        <List.Item>
          <List.Header>Doi</List.Header>
          <Link to={linkdoi}>{doi.replace("https://doi.org/", "")}</Link>
        </List.Item>
      )}
      {journal && (
        <List.Item>
          <List.Header>Journal</List.Header>
          {journal}
        </List.Item>
      )}
      {source && (
        <List.Item>
          <List.Header>Source</List.Header>
          {source}
        </List.Item>
      )}
    </List>
  );
}

const formatText = (text) => {
  if (!text) return null;
  return text.split("\n").map((str, i) => <p key={i}>{str}</p>);
};

function Content({
  title,
  content,
  doi,
  journal,
  publishDate,
  source,
  titleSection
}) {
  return (
    <ContainerContent>
      <Header as="h1">{title}</Header>
      {content && (
        <>
          <Header as="h3">{titleSection}</Header>
          {formatText(content)}
        </>
      )}
      <Meta {...{ journal, publishDate, source, doi }} />
    </ContainerContent>
  );
}

function Related({ id, specter }) {
  const query = new URLSearchParams();
  query.set("id", id);
  query.set("summary", "short");
  query.set("hits", 5);
  if (specter) query.set("use-specter", true);
  const { loading, response, error } = Get(
    "/search/?" + query.toString()
  ).state();

  if (loading) return <Loading message="Searching..." />;
  if (error)
    return <Error message={error.message || "Unknown search error..."} />;

  console.log(response);
  if (!("children" in response.root)) return null;
  return (
    <Tab.Pane>
      <React.Fragment>
        {response.root.children.map((article, i) => (
          <ResultCard key={i} {...article} />
        ))}
        <Link to={`/search/?query=related_to:${id}&use-specter=${specter}`}>
          Show more
        </Link>
      </React.Fragment>
    </Tab.Pane>
  );
}

function CitedBy({ citedBy, total, offset, onOffsetChange }) {
  return (
    <Container>
      {citedBy.slice(offset, offset + 10).map((id) => (
        <Citation key={id} id={id} />
      ))}
      <Pagination {...{ total, offset, onOffsetChange }} />
    </Container>
  );
}

function Citation({ id }) {
  const { loading, response, error } = Get(
    `/document/v1/covid-19/doc/docid/${id}?fieldSet=doc:title,abstract,doi,abstract_t5,journal,source,timestamp,license`
  ).state();

  if (loading) return <Loading message="Loading..." />;
  if (error)
    return <Error message={error.message || `Failed to load article #${id}`} />;

  console.log(response);
  return <ResultCard {...response} />;
}

function Article({ id }) {
  const url = `/document/v1/article/article/docid/${id.replace(/%2F/g, "/")}`;
  
  function reducer(state, action) {
    const { response, error, url } = state;
    switch (action.type) {
      case "loading":
        return {
          loading: true,
          url: action.url,
          response: null,
          error: null,
        };
      case "success":
        return { loading: false, response: action.response, error, url };
      case "error":
        return { loading: false, error: action.error, response, url };
      default:
        throw new Error();
    }
  }

  const initialState = {
    loading: null,
    response: null,
    error: null,
    url: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
//   console.log(state, url);

  useEffect(() => {
    // console.log(state.url, url)
    if (state.url !== url) {
    //   console.log("loading", state, url);
      dispatch({ type: "loading", url: url });
    } else if (state.loading) {
    //   console.log("dispatch loading", state, url);
      let isCanceled = false;
      Get(url)()
        .then((response) => {
          if (!isCanceled) {
            // console.log("dispatch success");
            // console.log(response);
            dispatch({
              type: "success",
              response: response,
            });
          } else {
            console.log("response cancled");
          }
        })
        .catch((error) => {
          if (!isCanceled) {
            // console.log("dispatch error");
            dispatch({ type: "error", error: error });
          } else {
            // console.log("error cancled");
          }
        });
      return () => {
        // console.log("cancel", state, url);
        isCanceled = true;
      };
    } else {
        // console.log("same body useEffect", state, url);
    }
  });

  if (state.loading || state.loading === null) return <Loading message="Loading..." />;
  if (state.error)
    return (
      <Error message={state.error.message || `Failed to load article #${id}`} />
    );

  return (
    <React.Fragment>
      <ContainerContent>
        <Content {...state.response.fields} />
      </ContainerContent>
    </React.Fragment>
  );
}

export default Article;
