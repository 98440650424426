import React, { useEffect, useReducer } from "react";
import { Container, Modal, Header, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Error, Loading } from "../shared/components/Message";
import SearchForm from "../shared/components/SearchForm";
import { onSearch, getSearchState, generateApiQueryParams } from "./Utils";
import ResultCard from "./ResultCard";
import SearchOptions from "./SearchOptions";
import Pagination from "../shared/components/Pagination";
import Sidebar from "./Sidebar";
import { Post } from "../shared/Fetcher";

const ContainerSearch = styled(Container)`
  &&& {
    font-size: 1rem;
    width: 100%;
    margin: 0.5em auto 0;
    padding: 1rem 0;
    display: flex;
    #sidebar {
      width: 30%;
      max-width: 250px;
      height: fit-content;
      margin-right: 1em;
      padding: 0.5em;
      border-radius: 0.28571429rem;
      .ui.button {
        width: calc(100% - 1em);
        padding: 0.78125rem 0.4rem;
        line-height: 1.4285em;
        border-radius: 1.3rem;
        margin: 0 0.5em 0.5em;
        background: rgba(0, 90, 142, 0.1);
        color: #3f9dd8;
      }
    }
    #search_results {
      flex: 1;
      margin-top: 0.5em; // Must match #sidebar top padding
    }

    #search_form {
      flex: 0.4;
      margin-top: 0.1em; // Must match #sidebar top padding
      background: rgba(0, 90, 142, 0.1);
      color: #3f9dd8;
    }

    #no_matches {
      text-align: center;
      margin: 2rem;
    }
  }
`;

function NoMatches({ query }) {
  return (
    <div id="no_matches">
      <h1>¯\_(ツ)_/¯</h1>
      <br />
      No matches for <b>{query || "empty query"}</b>
    </div>
  );
}

function SearchResults({ articles, query, loading, error, response_error }) {
  if (loading === null || loading) return <Loading message="Searching ... Semantic Search takes more time" />;
  if (error)
    return <Error message={error.message || "Unknown search error..."} />;
  if (response_error)
    return <Error message={response_error[0].summary || "Unknown search error..."} />;
  if (articles.length === 0) return <NoMatches query={query} />;

  return (
    <>
      {articles.map((article, i) => (
        <ResultCard key={i} {...article} />
      ))}
    </>
  );
}

function Search() {
  const searchState = getSearchState();
  const query = generateApiQueryParams();
  
  // console.log("state", searchState);
  // console.log("Query", query);
  
  function reducer(state, action) {
    const { response, error, body } = state;
    switch (action.type) {
      case "loading":
        return {
          loading: true,
          body: action.body,
          response: null,
          error: null,
        };
      case "success":
        return { loading: false, response: action.response, error, body };
      case "error":
        return { loading: false, error: action.error, response, body };
      default:
        throw new Error();
    }
  }

  const initialState = {
    loading: null,
    response: null,
    error: null,
    body: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  //   console.log(state);

  useEffect(() => {
    const requestOptions = {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(query),
    };
    const url = "/search/";
    if (state.body !== requestOptions.body) {
      // console.log("loading", state, requestOptions);
      dispatch({ type: "loading", body: requestOptions.body });
    } else if (state.loading) {
      let isCanceled = false;
      if (query.query.trim() === "") {
        if (!isCanceled) {
          // console.log("dispatch error");
          dispatch({
            type: "error",
            error: {
              message: "Do not accept empty query; Please specify your query",
            },
          });
        } else {
          // console.log("error cancled");
        }
      } else {
        // new Promise((resolve) => {
        //   setTimeout(() => {
        //     resolve(Post(url, requestOptions)());
        //   }, 5000);
        // })

        Post(url, requestOptions)()
          .then((response) => {
            if (!isCanceled) {
              // console.log("dispatch success");
              // console.log(response);
              dispatch({
                type: "success",
                response: response,
              });
            } else {
              // console.log("response cancled");
            }
          })
          .catch((error) => {
            if (!isCanceled) {
              // console.log("dispatch error");
              dispatch({ type: "error", error: error });
            } else {
              // console.log("error cancled");
            }
          });
        return () => {
          // console.log("cancel", state, query);
          isCanceled = true;
        };
      }
    } else {
      // console.log("same body useEffect", state, query);
    }
  }, [state, query]);

  const totalCount = state.response?.root?.fields?.totalCount || 0;
  const searchTime = state.response?.timing?.searchtime || 0;
  const response_error = state.response?.root?.errors || null;
  //console.log(response_error)
  // Sort results to make sure the grouping hit is first
  const groupingId = "group:root:0";
  const [grouping, ...articles] = (state.response?.root?.children || []).sort(
    ({ id: id1, relevance: rev1 }, { id: id2, relevance: rev2 }) =>
      id1 === groupingId ? -1 : id2 === groupingId ? 1 : rev2 - rev1
  );
  // Combine grouping data with search state (search related query parameters, i.e. which filters have been enabled)
  const valuesState = !grouping?.children
    ? {}
    : grouping.children.reduce((obj, { label, children }) => {
        obj[label] = children.map(({ value, fields }) => ({
          value,
          count: fields["count()"],
          checked: searchState[label].includes(value),
        }));
        return obj;
      }, {});
  // console.log(totalCount, grouping, articles, valuesState);
  return (
    <React.Fragment>
      <ContainerSearch>
        <Sidebar
          onSearch={onSearch}
          valueStateJson={JSON.stringify(valuesState)}
          year={searchState.year}
        />

        <div id="search_results">
          <SearchForm onSearch={onSearch} query={searchState.query} />
          <SearchOptions
            totalCount={totalCount}
            onSearch={onSearch}
            searchTime={searchTime}
            ranking={searchState.ranking}
            contenttype={searchState.contenttype}
            fieldset={searchState.fieldset}
          />

          <SearchResults
            articles={articles}
            query={searchState.query}
            loading={state.loading}
            error={state.error}
            response_error={response_error}
          />
          <Pagination
            total={totalCount}
            offset={searchState.offset}
            numPerPage={searchState.hit}
            onOffsetChange={(offset) => onSearch({ offset })}
          />
        </div>
      </ContainerSearch>
    </React.Fragment>
  );
}

export default Search;
