import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  GridColumn,
  Dropdown,
  Label,
  Icon,
} from "semantic-ui-react";
import cloneDeep from "lodash/cloneDeep";

const filters = [
  {
    name: "Publisher",
    field: "publisher",
  },
  {
    name: "Journal",
    field: "journal",
  },
  // {
  //   name: "Published",
  //   field: "year",
  // },
  {
    name: "Author",
    field: "author",
  },
];

const PaddedCheckbox = styled(Checkbox)`
  && {
    display: block;
    padding: 2px;
    font-size: 0.9em;
    label {
      color: #303030;
    }
  }
`;

function Checkboxes({ name, field, values, setboxState }) {
  if (!values || values.length === 0) return null;
  const onChange = (event, { value, checked }) => {
    // The new selected checkboxes are the ones that were previously selected
    // and the current value of the checkbox that triggered the event
    const selected = values.map(({ value: oValue, count, checked: oChecked }) =>
      oValue === value
        ? {
            value: oValue,
            count: count,
            checked: checked,
          }
        : {
            value: oValue,
            count: count,
            checked: oChecked,
          }
    );
    setboxState(selected, field);
  };

  return (
    <Form.Field>
      <label>{name}</label>
      {values
        .filter(
          ({ value }) =>
            value.length > 0 && !(field === "year" && value === "1970")
        )
        .map(({ value, count, checked }, i) => (
          <PaddedCheckbox
            key={i}
            name={name}
            value={value}
            onChange={onChange}
            label={`${value} (${count})`}
            checked={checked}
          />
        ))}
    </Form.Field>
  );
}

const timeOptions = [
  {
    text: "All time",
    value: "all",
  },

  {
    text: "1 year",
    value: "1y",
  },
  {
    text: "3 years",
    value: "3y",
  },
  {
    text: "5 years",
    value: "5y",
  },
  {
    text: "10 years",
    value: "10y",
  },
];

function Sidebar({ onSearch, valueStateJson, year }) {
  const [renderCount, setRenderCount] = useState(1);
  const [filterStateJson, setfilterStateJson] = useState(valueStateJson);
  const [filterYear, setfilterYear] = useState(year);

  useEffect(() => {
    setRenderCount(prev => prev + 1);
    setfilterStateJson(valueStateJson);
    setfilterYear(year);
    //console.log("useEffect");
  }, [valueStateJson, year]);

  const filterState = JSON.parse(filterStateJson);

  const setboxState = (selected, field) => {
    var newfilterState = cloneDeep(filterState);
    newfilterState[field] = selected;
    setfilterStateJson(JSON.stringify(newfilterState));
  };

  // console.log("render count", renderCount);
  // console.log("valueState", valueStateJson);
  // console.log("filterSitate", filterStateJson);
  // console.log("year", year);
  // console.log("filterYear", filterYear);

  return (
    <div
      id="sidebar"
      className="ui form"
      style={{ backgroundColor: "#e6eff5" }}
    >
      <Grid>
        <GridColumn width={8}>
          <Button
            onClick={() => {
              if (filterStateJson !== valueStateJson) {
                filters.forEach(({ field }, index) => {
                  if (
                    filterState.hasOwnProperty(field) &&
                    Array.isArray(filterState[field])
                  ) {
                    var newselected = cloneDeep(filterState[field]);
                    newselected.forEach((item) => {
                      item.checked = false;
                    });
                    setboxState(newselected, field);
                  }
                });
              }
              var onSearchParas = {};
              // onSearchParas["year"] = null;
              filters.forEach(({ field }, index) => {
                onSearchParas[field] = [];
              });
              onSearch(onSearchParas);
            }}
          >
            Clear
          </Button>
        </GridColumn>
        <GridColumn width={8}>
          <Button
            onClick={() => {
              var onSearchParas = {};
              // onSearchParas["year"] = filterYear
              Object.entries(filterState).forEach(([key, value], index) => {
                var selected = value
                  .filter(({ checked }) => checked)
                  .map(({ value }) => value);
                onSearchParas[key] = selected;
              });
              onSearch(onSearchParas);
            }}
          >
            Submit
          </Button>
        </GridColumn>
      </Grid>
      {/* <label>Filter by Date Published </label>
      <Dropdown
        placeholder="Select time:"
        selection
        options={timeOptions}
        value={filterYear}
        onChange={(event, { value }) => {
          //console.log('year change', value)
          setfilterYear(value);
        }}
      /> */}
      {filters.map(({ name, field }) => (
        <Checkboxes
          key={field}
          name={name}
          field={field}
          values={filterState[field]}
          setboxState={setboxState}
        />
      ))}
      {filterStateJson !== valueStateJson && (
        <Label>
          <Icon name="question circle" /> Please click submit to apply filter
        </Label>
      )}
    </div>
  );
}

export default Sidebar;
