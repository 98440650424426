import React, { useEffect, useReducer } from "react";
import { Modal, Header, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";
import SyntaxExplain from "./syntax";

const Container = styled.div`
  &&& {
    padding: 0.5em;
    > span {
      float: right;
    }
  }
`;

const fieldsets = [
  {
    text: "Open Access Papers",
    value: "oa",
  },
  {
    text: "All Papers",
    value: "all",
  },
];

const contenttypes = [
  {
    text: "Abstract",
    value: "abs",
  },
  {
    text: "Full Text",
    value: "all",
  },
];

const rankings = [
  {
    text: "Keyword Matching",
    value: "bm25",
  },
  {
    text: "Semantic Matching",
    value: "semantic",
  },
  // {
  //   text: "both",
  //   value: "fusion",
  // },
];

function ModalSyntax() {
  const [open, setOpen] = React.useState(false);
  const StyledModal = styled(Modal)`
    .modal-content .has-line-data code {
      color: rgb(240, 128, 128);
    }
  `;
  const StyledLink = styled.a`
    &&& {
      text-decoration: underline;
    }
  `;
  return (
    <StyledModal
      closeIcon
      open={open}
      trigger={<StyledLink> Search Syntax </StyledLink>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon="archive" content="Search Syntax" />
      <Modal.Content>
        <SyntaxExplain />
      </Modal.Content>
    </StyledModal>
  );
}

function SearchOptions({ totalCount, searchTime, fieldset, ranking, contenttype, onSearch }) {
  
  return (
    <>
      <Container>
        {totalCount > 0 && (
          <>
            <b>{totalCount}</b> matches takes <b>{searchTime.toFixed(2)}</b> seconds
          </>
        )}
        <span>
          {"Searching in "}
          
          <Dropdown
            inline
            defaultValue={
              contenttypes.find(
                ({ value }, i) => contenttype === value || (!contenttype && i === 0)
              ).value
            }
            options={contenttypes.map((flds, id) => ({ id, ...flds }))}
            onChange={(event, { value }) => onSearch({ contenttype: value })}
          />
          {" from "}
          <Dropdown
            inline
            defaultValue={
              fieldsets.find(
                ({ value }, i) => fieldset === value || (!fieldset && i === 0)
              ).value
            }
            options={fieldsets.map((flds, id) => ({ id, ...flds }))}
            onChange={(event, { value }) => onSearch({ fieldset: value })}
          />

          {" and sorting by "}
          <Dropdown
            inline
            defaultValue={
              rankings.find(
                ({ value }, i) => ranking === value || (!ranking && i === 0)
              ).value
            }
            options={rankings.map((rnk, id) => ({ id, ...rnk }))}
            onChange={(event, { value }) => onSearch({ ranking: value })}
          />
        </span>
      </Container>
    </>
  );
}

export default SearchOptions;
