import { navigate } from "@reach/router";

const select = `all( 
all(group(journal) max(40) order(-count()) each(output(count())))
)`
  .split("\n")
  .map((s) => s.trim())
  .join("");

// all(group(publisher) order(-count()) each(output(count())))
const onSearch = (params) => {
  const urlParams = new URLSearchParams(window.location.search);

  for (let [key, value] of Object.entries(params)) {
    urlParams.delete(key);
    if (Array.isArray(value)) value.forEach((v) => urlParams.append(key, v));
    else if (value) urlParams.set(key, value);
  }
  // Offset must be reset whenever result set changes, which we assume may be
  // every time the URL changes due to other interactions than with pagination.
  if (!params.hasOwnProperty("offset")) urlParams.delete("offset");

  navigate("/?" + urlParams);
};

const orCombiner = (field, array, range = false) =>
  array.length
    ? "(" + array.map((s) => `${field} contains "${s}"`).join(" or ") + ")"
    : null;

function subtractYears(numOfYears, date = new Date()) {
  date.setFullYear(date.getFullYear() - numOfYears);
  return date.getTime();
}

const timeValue = {
  "all": 0,
  "1y": subtractYears(1),
  "3y": subtractYears(3),
  "5y": subtractYears(5),
  "10y": subtractYears(10),
};

const timefilter = (year) => {
  if (year === "all") return null;
  else return `timestamp > ${timeValue[year]}`;
};

const type2query = (contenttype) => {
  if (contenttype === "abs") return 'isAbstract = true';
  else return 'isAbstract = false';
};

const field2query = (fieldset) => {
  if (fieldset === "oa") return 'isOA = true';
  else return null;
};

const generateApiQueryParams = () => {
  const {
    query,
    publisher,
    journal,
    ranking,
    fieldset,
    contenttype,
    hit,
    offset,
    author,
    year,
  } = getSearchState();

  const filter = [
    orCombiner("journal", journal),
    orCombiner("authors", author),
    timefilter(year),
    type2query(contenttype),
    field2query(fieldset)
  ]
    .filter((s) => s)
    .join(" and ");

  const apiQuery = {
    type: "weakAnd",
    hits: hit,
    offset: offset,
    "presentation.summary": "full",
    "timeout": "10s",
    // trace: { level: 1 },
  };
  apiQuery["ranking.profile"] = ranking;
  apiQuery["model.defaultIndex"] = "default"
  apiQuery["query"] = ` ${query} `;

  if (filter === "") {
    apiQuery["yql"] = `select * from article where PROXY_PLACEHOLDER_STRING | ${select}`;
  } else {
    apiQuery["yql"] = `select * from article where ${filter} and PROXY_PLACEHOLDER_STRING | ${select}`;
  }
  //console.log(apiQuery)
  return apiQuery;
};

const getSearchState = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    query: urlParams.get("query") || "",
    journal: urlParams.getAll("journal"),
    publisher: urlParams.getAll("publisher"),
    year: urlParams.get("year") || "all",
    author: urlParams.getAll("author"),
    ranking: urlParams.get("ranking") || "bm25",
    fieldset: urlParams.get("fieldset") || "all",
    contenttype: urlParams.get("contenttype") || "abs",
    offset: Number(urlParams.get("offset")) || 0,
    hit: Number(urlParams.get("hit")) || 10,
  };
};

export { getSearchState, onSearch, generateApiQueryParams };
